import { OrganizationDetailsTasks } from '../components/Onboarding/OrganizationDetailsTasks';

export function OrganizationTasksRoute() {
  return <OrganizationDetailsTasks />;
}

export const Component = OrganizationTasksRoute;

export function clientLoader() {
  return null;
}
